import { notification } from 'antd';
import { useQuery } from 'react-query';

import storage from '@/utils/storage';

import { appsResponse, returnRes } from '../../constants';
import { fetchXapUsers, getApps } from '../index';

export const useGetAllApps = () =>
  useQuery<appsResponse, Error, returnRes[]>(['app'], () => getApps(), {
    select: (res) => {
      if (res?.apps.length > 0) {
        return res?.apps
          .filter((elem) => elem !== 'xstak-copilot-admin')
          .map((app) => {
            const label =
              app === 'sd'
                ? 'SHOPDESK'
                : app === 'hypr'
                ? 'HYPR'
                : app === 'oe'
                ? 'OE'
                : app === 'pim'
                ? 'PIM'
                : app === 'or'
                ? 'omniretain'.toUpperCase()
                : app === 'xpay'
                ? 'XStakPay'.toUpperCase()
                : app;
            return {
              label,
              value: app,
            };
          });
      } else return [];
    },
    onError: (response: any) => {
      notification.error({ message: response?.message || 'Something went wrong.' });
    },
  });

export const useFetchXapUsers = (store_id: string) => {
  const realm = storage.getRealm();
  return useQuery<any>(
    ['xap-users', store_id],
    () => {
      return fetchXapUsers(realm);
    },
    {
      onSuccess(data) {
        console.log(data);
        // onSuccess(data);
      },
      enabled: !!store_id,
    }
  );
};
