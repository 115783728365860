import { sdStoresRes, treeChildren, TreeDataType } from './types';

export const isEmpty = (value: string | number | null | undefined) => {
  if (value || value === 0) {
    return false;
  }
  return true;
};

// check hypr Permissions
export const checkPermissions = (
  Permissions: Array<string | number>,
  state: Array<string | number>
) => {
  if (state && Permissions) {
    return Permissions.some((code) => state.some((permission) => permission === code));
  }
  return false;
};

// check if certain role has access to fields
export const hyperRoles = (id: string, func: any, op?: boolean, isZod?: boolean): boolean => {
  if (isZod) {
    return func.role?.some((elem: string) => elem !== id);
  }
  return func().role?.some((elem: string) => {
    if (op) {
      return elem === id;
    }
    return elem !== id;
  });
};

export function getCompany(company: string, app: Array<string>): string | boolean {
  return app && app.length > 0 ? app.filter((elem: any) => elem === company).toString() : false;
}

export function CheckCurrentRole(key: string, role: string, fun: boolean): boolean {
  return key === role && fun;
}

export const getAppRoles = (data: Array<string>, appName: string, index = 0) =>
  data.filter((elem) => elem.includes(appName)).map((item) => item.split('_')[index]);

export const setRoleData = (data: TreeDataType, value: Array<string>) => {
  const isHyper = (index: number, key: string) =>
    value?.map((elem) => elem.split('_')[index]).includes(key);
  return data.map((elem) => {
    const hyprCondition = CheckCurrentRole(elem.key, 'hypr-roles', isHyper(1, 'hypr'));
    const sdCondition = CheckCurrentRole(elem.key, 'SD-roles', isHyper(1, 'sd'));
    const orCondition = CheckCurrentRole(elem.key, 'OR-roles', isHyper(1, 'or'));
    const isCurrentHypr = CheckCurrentRole(elem.key, 'hypr-roles', !isHyper(1, 'hypr'));
    const isCurrentSD = CheckCurrentRole(elem.key, 'SD-roles', !isHyper(1, 'sd'));
    const isCurrentOR = CheckCurrentRole(elem.key, 'OR-roles', !isHyper(1, 'or'));
    const current = hyprCondition || sdCondition || orCondition;
    const isCurrentIDInValue = (id: string) => value.includes(id);
    if (current) {
      const disableChildren: {
        value: string;
        title: string;
        id: string | number;
      }[] = [];
      elem?.children?.forEach((child) => {
        disableChildren.push({
          ...child,
          ...(isCurrentIDInValue(child.value)
            ? { disableCheckbox: false }
            : { disableCheckbox: true }),
        });
      });
      return {
        ...elem,
        children: disableChildren,
      };
    }

    if (isCurrentHypr || isCurrentSD || isCurrentOR) {
      const disableChildren: any = [];
      elem?.children?.forEach((child: any) => {
        disableChildren.push({
          ...child,
          disableCheckbox: false,
        });
      });
      return {
        ...elem,
        disableCheckbox: true,
        children: disableChildren,
      };
    }
    return elem;
  });
};

// return ORRolesData

export const ORRoles = (OrData: any) => {
  const roles =
    OrData?.AuthGroup && OrData?.AuthGroup.length > 0
      ? OrData?.AuthGroup.map((elem: any) => ({
          value: elem.id + '_or',
          title: elem.name,
          id: elem.id,
        }))
      : [];
  return [
    {
      title: 'OR Roles',
      value: 'OR-roles',
      key: 'OR-roles',
      children: roles,
      disableCheckbox: true,
    },
  ];
};

export const fakeUserData = () => {
  const n = new Array(100).fill(1).map((elem, i) => i);
  const users: any = [];
  for (const i of n) {
    const role = i <= 10 ? 'Admin' : i <= 20 ? 'Super-Admin' : i <= 30 ? 'new role' : 'user';
    users.push({
      name: 'user' + i,
      email: 'user' + i + '@sd.co',
      username: 'user' + i + '@sd.co',
      id: Math.random().toString().slice(2, 11),
      role,
      phone: Math.random().toString().slice(2, 11),
      disabled: 'false',
    });
  }
  return users;
};

export const RolesTitles = (
  app: string,
  Roles: Array<string | number> | null,
  data: TreeDataType
) => {
  let element: any = {};
  const oe: Array<string> = [];

  Roles?.forEach((role) => {
    data.forEach((elem) => {
      elem?.children?.forEach((subRole) => {
        if (subRole.value.split('_')[0] === role) {
          if (app === 'oe') {
            oe.push(subRole.title);
          }
          element = subRole;
        }
      });
    });
  });
  if (app === 'oe') {
    return oe;
  } else
    return {
      [app]: element?.title,
    };
};

export function returnSdBrands(SDStores: sdStoresRes | undefined) {
  const finalPayload: TreeDataType = [];
  if (SDStores?.data && SDStores?.data.length > 0) {
    let stores: treeChildren = [];
    SDStores?.data?.forEach((elem) => {
      stores = [];
      if (elem.stores.length > 0) {
        elem.stores.forEach((item) => {
          stores.push({
            value: item.id + '_' + item.brand_id + '_store',
            title: item.name,
            id: item.id,
          });
        });
      }

      finalPayload.push({
        value: elem.brand_id + '_brand',
        title: elem.brand_name,
        key: elem.brand_id + '_brand',
        children: stores,
      });
    });

    return [...finalPayload];
  }
  return [];
}

export const generateQueryString = ({
  page,
  pageSize,
  role,
}: {
  page: number;
  pageSize: number;
  role?: string | null | undefined;
}) => {
  const query: any = {};

  if (page) query.per_page = pageSize || 20;
  if (pageSize) query.page = page;
  query.clientTimeOffset = '-300';
  if (!isEmpty(role)) query.role_id = role;
  const params = `?${new URLSearchParams(query).toString()}`;
  return params;
};

export const isAppExist = (app: Array<string>) => {
  const currApp = ['oe', 'hypr', 'sd', 'or', 'xpay', 'pim'];
  let isExists = false;
  for (const a of currApp) {
    if (app.includes(a)) {
      isExists = true;
    }
  }
  return isExists;
};
