import React, { useEffect, useState } from 'react';

import { SelectField } from '@/components/Form';
import storage from '@/utils/storage';

import { fetchXapPimUsers, fetchXapUsers, fetchXapXPayUsers } from '../api';
import {
  useGetHyprUsers,
  useGetOeUsers,
  useGetOrCompany,
  useGetorUsers,
  useGetSDBrandStores,
  useGetSDUsers,
  useHyprRole,
} from '../api/hooks';
import { generateQueryString, sdDataType } from '../constants';

import { UserTables } from './common';
const sdInitialData = {
  stores: [],
  brands: [],
  brand_id: '',
  store_id: '',
  currStores: [],
  sdUsers: [],
  totalNumberOfRecords: null,
};

export const AllUsers = ({
  appsData,
  edit,
  btnLoading,
  saveParentRole,
  refetchUser,
  userId,
}: {
  appsData: any;
  btnLoading: boolean;
  refetchUser?: boolean;
  edit: (args: any) => void;
  saveParentRole: (args: string | null) => void;
  userId: string;
}) => {
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const hyprInitialState = {
    role: null,
    hyprUser: [],
    hyprRoles: [],
    hyprRoleId: [],
    currRole: '',
    label: '',
    params: generateQueryString({ page, pageSize }),
  };

  const [allUsers, setAllUsers] = useState<any>([]);
  const [fetchXap, setFetchXap] = useState(false);
  // const [tempUsers, setTempUser] = useState<any>([]);
  const [offSet] = useState(0);
  const [role, setRole] = useState<string | null>('');
  const [xapLoading, setXapLoading] = useState(false);
  // const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
  useEffect(() => {
    refetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchUser]);

  const refetchUsers = () => {
    if (role && role === 'sd') {
      refetchSDUsers();
    }
    if (role && role === 'hypr') {
      refetchHyprUser();
    }
    if (role && role === 'or') {
      refetchOrUsers();
    }
    if (role && role === 'xpay') {
      fetchXpayOrPimUsers('xpay');
    }
    if (role && role === 'pim') {
      fetchXpayOrPimUsers('pim');
    }
    if (role && role === 'oe') {
      refetchOeUser();
    }
  };

  const [hypr, setHypr] = useState<{
    // role: string | null;
    hyprUser: Array<string>;
    hyprRoles: any;
    hyprRoleId: Array<string>;
    currRole: string;
    params: string;
    label: string;
  }>(hyprInitialState);

  const [sdData, setSdData] = useState<sdDataType>(sdInitialData);
  const { hyprRoles, hyprRoleId, currRole, params, label } = hypr;

  const { stores, brands, brand_id, currStores, store_id } = sdData;
  const hyprApp = role === 'hypr' ? 'hypr' : false;
  const sdRole = role === 'sd' ? 'sd' : false;
  const orRole = role === 'or' ? 'or' : false;
  const oeRole = role === 'oe' ? 'oe' : false;
  useGetSDBrandStores(sdRole, (SDstores, SDbrands) => {
    setSdData((prev) => ({
      ...prev,
      stores: SDstores,
      brands: SDbrands,
    }));
  });

  const {
    isFetching: hyprFetching,
    isLoading: HyprUserLoading,
    refetch: refetchHyprUser,
  } = useGetHyprUsers(params, currRole, (data) => {
    setHypr((prev) => ({ ...prev, hyprUser: data }));
    setAllUsers(() => {
      return data.map((user: any) => ({
        ...user,
        role: label,
        roleId: user.role,
        email: user.username,
      }));
    });
    setFetchXap(!fetchXap);
    // setTempUser(data);
  });
  useHyprRole(hyprApp, (hyprData) => {
    setHypr((prev) => ({ ...prev, hyprRoles: hyprData }));
  });
  const handlePageChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
    setHypr((prev) => ({ ...prev, params: generateQueryString({ page, pageSize, role }) }));
  };
  const {
    isFetching: SdFetching,
    isLoading: SdUserLoading,
    refetch: refetchSDUsers,
  } = useGetSDUsers({ brand_id, store_id, pageSize, page }, (args) => {
    setSdData((prev) => ({
      ...prev,
      sdUsers: args?.data || [],
      totalNumberOfRecords: args?.page?.totalElements,
    }));
    const data =
      args?.data && args.data.length > 0
        ? args?.data.map((elem) => ({
            ...elem,
            username: elem.email,
            role: elem.user_role_name,
            app: 'sd',
            disabled: elem.status,
          }))
        : [];
    setAllUsers(data);
    setFetchXap(!fetchXap);
  });
  const {
    refetch: refetchOeUser,
    isLoading: oeUserLoading,
    isFetching,
  } = useGetOeUsers(oeRole, (res) => {
    setAllUsers(res);
    setFetchXap(!fetchXap);
  });
  React.useEffect(() => {
    if (allUsers.length > 0) {
      fetchXAPUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchXap]);

  React.useEffect(() => {
    if (role === 'xpay') {
      fetchXpayOrPimUsers('xpay');
    } else if (role === 'pim') {
      fetchXpayOrPimUsers('pim');
    }
  }, [role]);

  const fetchXpayOrPimUsers = async (product: string) => {
    const realm = storage.getRealm();
    let users;

    try {
      setXapLoading(true);

      if (product === 'xpay') {
        users = await fetchXapXPayUsers(realm);
      } else if (product === 'pim') {
        users = await fetchXapPimUsers(realm);
      }
      setAllUsers(() => {
        return users?.data?.map((user: any, id: number) => ({
          ...user,
          id: id + 1,
          name: user.firstName + ' ' + user.lastName,
          phone: user?.phone ?? '-',
          disabled: user.enabled === true ? '1' : '0',
          role: 'Admin',
          ...(product === 'xpay' && { isXpay: true }),
          ...(product === 'pim' && { isPim: true }),
          showId: id + 1,
        }));
      });
    } catch (error) {
      setAllUsers([]);
    } finally {
      setXapLoading(false);
    }
  };

  const fetchXAPUsers = async () => {
    const realm = storage.getRealm();
    try {
      setXapLoading(true);
      const users = await fetchXapUsers(realm);
      setAllUsers((prev: any) => {
        const newUser: any = [];
        prev.forEach((elem: any) => {
          const TempUsers: any = [];
          users?.data?.data?.forEach((user: any) => {
            let cond;
            if (!!orRole || !!oeRole) {
              cond = user.username === elem.username;
            } else {
              cond = user.email === elem.email;
            }
            if (cond) {
              let status = '';
              if (
                (role === 'oe' && elem.enabled) ||
                (role === 'sd' && elem.status === '1') ||
                (role === 'or' && elem.is_active) ||
                (role === 'hypr' && !elem.disabled)
              ) {
                status = '1';
              } else {
                status = '0';
              }
              TempUsers.push({
                ...elem,
                name: user.firstName + ' ' + user.lastName,
                disabled: status,
              });
            }
          });
          newUser.push(...TempUsers);
        });
        return newUser.map((elem: any, index: number) => ({
          ...elem,
          role: elem.role || elem.oeRoles || '-',
          app: role,
          showId: index + 1,
        }));
      });
      setXapLoading(false);
    } catch (error) {
      setXapLoading(false);
      setAllUsers((prev: any) => prev);
    }
    setXapLoading(false);
  };
  const {
    data: orCompany,
    isLoading: orCompanyLoading,
    isFetching: orCompanyFetching,
  } = useGetOrCompany(orRole);
  const company =
    orCompany?.data?.BaseConfiguration &&
    orCompany?.data?.BaseConfiguration.length > 0 &&
    orCompany?.data?.BaseConfiguration[0]?.company;

  const {
    isFetching: orFetching,
    isLoading: OrUserLoading,
    refetch: refetchOrUsers,
  } = useGetorUsers(
    { queryVariable: company, limit: pageSize, pageNumber: offSet, orApp: orRole },
    (res) => {
      const data =
        res?.data && res?.data?.PaginatedUser?.data.length > 0
          ? res?.data?.PaginatedUser?.data.map((elem) => {
              const roleId =
                typeof elem.group[0].id?.toString() === 'undefined'
                  ? '0_or'
                  : elem.group[0].id + '_or';
              const active = elem.is_active === false ? '0' : '1';
              return {
                ...elem,
                name: elem.complete_name,
                username: elem.username,
                role: elem.group[0].name,
                disabled: active,
                roleId,
                app: 'or',
              };
            })
          : [];
      setAllUsers(data);
      setFetchXap(!fetchXap);

      // setTempUser(data);

      // setTotalNumberOfRecords(res?.data?.count);
    }
  );
  const loading =
    oeUserLoading ||
    isFetching ||
    OrUserLoading ||
    SdUserLoading ||
    HyprUserLoading ||
    orCompanyLoading ||
    hyprFetching ||
    SdFetching ||
    orFetching ||
    orCompanyFetching ||
    xapLoading;
  console.log(oeUserLoading, isFetching, ':: oeUserLoading');
  return (
    <>
      <div className="user_roles_select">
        <SelectField
          placeholder="Select App"
          label="Select App"
          // size="large"
          value={role}
          options={appsData || []}
          fieldNames={{ label: 'label', value: 'value' }}
          isSearchable={false}
          handleChange={(value: any) => {
            setRole(value);
            setAllUsers([]);
            // setTotalNumberOfRecords(0);
            // setTempUser([]);
            saveParentRole(value);

            setHypr(hyprInitialState);
            setSdData(sdInitialData);
          }}
        />
        {role === 'hypr' && (
          <div>
            <SelectField
              placeholder="Select Role"
              label="Select Role"
              // size="large"
              labelInValue={true}
              onDeselect={() => setAllUsers([])}
              value={hyprRoleId}
              options={hyprRoles || []}
              fieldNames={{ label: 'label', value: 'value' }}
              isSearchable={false}
              handleChange={(value: any) => {
                let params: string;
                if (value) {
                  params = generateQueryString({
                    page,
                    pageSize,
                    role: value?.value,
                  });
                }
                setHypr((prev) => ({
                  ...prev,
                  currRole: value?.value,
                  params,
                  hyprRoleId: value?.value,
                  label: value?.label,
                }));
              }}
            />
          </div>
        )}
        {role === 'sd' && (
          <div className="select_sd">
            <SelectField
              placeholder="Select Brand"
              label="Select Brand"
              // size="large"
              onDeselect={() => setAllUsers([])}
              value={brand_id}
              options={brands || []}
              fieldNames={{ label: 'label', value: 'value' }}
              isSearchable={false}
              handleChange={(value: any) => {
                const currStores = stores
                  .filter((item) => item.some((elem) => elem.brand_id === value))
                  .flat()
                  .map((brand) => ({ label: brand.name, value: brand.id }));
                setSdData((prev) => ({
                  ...prev,
                  brand_id: value,
                  currStores,
                  store_id: '',
                }));
                setAllUsers([]);
              }}
            />

            {Boolean(brand_id) && (
              <div>
                <SelectField
                  placeholder="Select Store"
                  label="Select Store"
                  // size="large"
                  value={store_id}
                  onDeselect={() => setAllUsers([])}
                  options={currStores || []}
                  fieldNames={{ label: 'label', value: 'value' }}
                  isSearchable={false}
                  handleChange={(value: any) => {
                    setSdData((prev) => ({
                      ...prev,
                      store_id: value,
                    }));
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex user-table">
        <UserTables
          {...{
            data: allUsers,
            handlePageChange,
            isLoading: loading,
            totalNumberOfRecords: allUsers?.length,
            pageSize,
            edit,
            role,
            brand_id,
            store_id,
            btnLoading,
            userId,
          }}
        />
      </div>
    </>
  );
};
